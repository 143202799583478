exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-products-codingcourses-js": () => import("./../../../src/pages/products/codingcourses.js" /* webpackChunkName: "component---src-pages-products-codingcourses-js" */),
  "component---src-pages-products-codingcourses-python-js": () => import("./../../../src/pages/products/codingcourses/python.js" /* webpackChunkName: "component---src-pages-products-codingcourses-python-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-termsofservice-js": () => import("./../../../src/pages/termsofservice.js" /* webpackChunkName: "component---src-pages-termsofservice-js" */)
}

